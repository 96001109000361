
import extractLocale from 'src/locales/extractLocale'
import { modifyUrl } from 'vike/modifyUrl'

function onBeforeRoute(pageContext) {
  const url = pageContext.urlParsed
  const { urlPathnameWithoutLocale, locale } = extractLocale(url.pathname)
  let urlLogical = modifyUrl(url.href, { pathname: urlPathnameWithoutLocale })

  if(urlLogical.startsWith(import.meta.env.VITE_PUBLIC_HOST)) {
    urlLogical = urlLogical.replace(import.meta.env.VITE_PUBLIC_HOST, '')     
  }

  return {
    pageContext: {
      // Make `locale` available as pageContext.locale
      locale,
      // Vike's router will use pageContext.urlLogical instead of pageContext.urlOriginal
      urlLogical
    }
  }
}
export default onBeforeRoute
